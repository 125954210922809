header {
    @include fixed(0,auto,auto,0,50);
    // @include absolute(0,auto,auto,0,50);
    // @include size(100%,$headerSize);
    @include size(100%,vw-calc(70));
    @include max(none,$headerSize);
    background: $headerBg;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$md} {
        // @include relative_auto();        
        // position: absolute;
        @include size(100vw,$headerSizeMobile);        
        @include relative_auto();
        display: block;

        position: sticky;
        top: 0;
        z-index: 100;
    }
    @media #{$sm} {
        // @include relative_auto();        
        // position: absolute;
        @include size(100vw,$headerSizeMobile);
        @include relative_auto();
        display: block;

        position: sticky;
        top: 0;
        z-index: 100;
    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hamburger {
            font-style: normal;            
            .to-open {
                display: block;        
                transform: translate(-2px,-5px);        
                font-size: 20px;
            }
            .to-close {
                display: none;                
                font-size: 40px;
                font-family: $globalFontLight;
                transform: translateY(-10px);
            }
            &.is-active {
                .to-open {
                    display: none;
                }
                .to-close {
                    display: block;
                }
            }
        }
        @media #{$lg} {
            &:nth-of-type(2) {
                height: 100%;
            }
        }
        @media #{$md} {
            .logo-img {
                max-height: 36px;
            }
            &:nth-of-type(1) {
                height: 100%;
            }
            &:nth-of-type(2) {
                display: block;
                @include size(100%,auto);
            }
        }
        @media #{$sm} {
            .logo-img {
                max-height: 36px;
            }
            &:nth-of-type(1) {
                height: 100%;
            }
            &:nth-of-type(2) {
                display: block;
                @include size(100%,auto);
            }
        }
    }    
    .hamburger {        
        @media #{$sm} {
            .to-open {
                @include size(35px,auto);                
            }
            .to-close {
                @include size(30px,auto);
                img {
                    transform: translate(-25%,0);
                }
            }
            img {
                width: 100%;
            }
        }        
    }
    @media #{$lg} {
        overflow: hidden;
        .wrapper {
            justify-content: flex-start;            
        }
    }
    nav {
        text-align: center;
        @include size(100%,100%);
        // display: flex;
        // justify-content: space-between;
        ul {
            list-style: none;
            padding: 0;
            margin-bottom: 0.1rem;
            @include size(100%,100%);
            @media #{$lg} {
                display: flex;
                justify-content: space-between;
            }
            li {
                display: inline-block;
                margin: 0 2vw;
                @media #{$lg} {
                    display: inline-flex;
                    align-items: center;
                    vertical-align: middle;
                    transform: translateY(-2px);                    
                    margin: 0 auto;
                    // height: 100%;
                    height: 104%;
                    padding: 0 1rem;

                    a {                        
                        height: 100%;
                        align-items: center;
                        display: flex;
                        line-height: 100%;
                    }

                    &:first-of-type {
                        margin-left: 0;
                        padding-left: 0;
                    }
                    &:nth-of-type(2) {
                        background: #fff200;                        
                    }
                    &:last-of-type {
                        margin-right: 0;
                        padding-right: 0;
                    }
                }
                @media #{$hg} {
                    margin: 0 rem-calc(50);
                }
                cursor: pointer;
                color: $colorPrimary;
                font-family: $globalFontBold;
                @include transition('short');
                // @include no-select();
                a {
                    text-decoration: none;
                    color: $colorPrimary;
                    &:hover {
                        text-decoration: none;
                    }
                }
                &.active {
                    color: $colorTertiary;
                    a {
                        color: $colorTertiary;
                    }
                }
                .sub-menu {
                    li {
                        a {
                            color: $colorPrimary !important;                            
                        }
                        &.active {
                            a {
                                color: $colorPrimary !important;                                
                            }
                        }
                    }
                }
            }            
        }
        
        @media #{$md} {
            @include transition('medium');
            transform: translateY(-130%);
            // @include absolute($headerSizeMobile,auto,auto,0,50);
            @include absolute(0,auto,auto,0,50);
            @include size(100%,auto);
            background: #fff;
            padding: rem-calc(50) 0;
            &:before {
                content: '';
                @include absolute(50%,auto,auto,50%,-1);
                @include size(100vw,100%);
                background: #fff;
                transform: translate(-50%,-50%);
            }
            ul {
                margin: 0 auto;
                max-width: vw-tablet(800);
                text-align: left;
                li {
                    display: block;
                    text-align: left;
                    margin-bottom: 0.5rem;
                    font-size: $fontSizeTabletL;
                    letter-spacing: -1.9px;
                    margin-left: 0;
                    color: #00295c;
                    .hide-lg {
                        display: none;
                    }
                }
                &.sub-menu {
                    li {
                        position: relative;
                        padding-left: 35px;
                        margin-bottom: vw-tablet(50);
                        &:first-of-type {
                            margin-top: rem-calc(50);
                        }
                        &:before {
                            content: '';
                            @include absolute(50%,auto,auto,0,5);
                            transform: translate(0,-50%);
                            @include size(18px,2px);
                            background: #00295c;                            
                            border-radius: 100px;
                        }
                    }
                }
            }            
            &.show {
                transform: translateY(0);
            }         
        }
        @media #{$sm} {
            @include transition('medium');
            transform: translateY(-130%);
            // @include absolute($headerSizeMobile,auto,auto,0,50);
            @include absolute(0,auto,auto,0,50);
            @include size(100%,100vh);
            background: #fff;
            padding: rem-calc(50) 0;
            ul {
                margin: 0 auto;
                max-width: 90%;
                text-align: left;
                li {
                    display: block;
                    text-align: left;
                    margin-bottom: vw-tablet(50);
                    font-size: $fontSizeMobileS;
                    color: #00295c;
                    letter-spacing: 0;
                    margin-left: 0;
                }
                &.sub-menu {
                    width: 100%;
                    max-width: 100%;
                    li {
                        position: relative;
                        padding-left: 35px;
                        margin-bottom: vw-tablet(50);
                        &:first-of-type {
                            margin-top: 4.5vw;
                        }
                        &:before {
                            content: '';
                            @include absolute(50%,auto,auto,0,5);
                            transform: translate(0,-50%);
                            @include size(30px,2px);
                            background: #00295c;      
                            border-radius: 100px;                      
                        }
                    }
                }
            }   
            &.show {
                transform: translateY(0);
            }         
        }
    }
}
