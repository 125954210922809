body,html {
	position: relative;
}

body {

	.breadcrumbs {
		@include absolute(80px,auto,auto,50%,12);
		// @include fixed(80px,auto,auto,50%,12);
		transform: translate(-50%,0);
		a,p,span {
			font-size: 0.7rem;
			color: #fff;
		}
		@media #{$lg} {
			// margin-top: -1rem;
		}
		@media #{$sm} {
			@include relative_auto();
			transform: translate(0,0);
			width: 100%;
			padding-bottom: 0.5rem;
			overflow-x: auto;
			white-space: nowrap;
			padding-right: 1rem;
			a {
				&:last-of-type {
					margin-right: 1rem;
				}
			}
		}
	}

	// &.homepage {
	// 	@media #{$lg} {
	// 		.product-image {
	// 			position: absolute;
	// 		}
	// 		.particles-bg {
	// 			position: absolute;
	// 		}
	// 	}
	// }

	&.long {
		@media #{$lg} {
			.product-image {
				position: fixed;
			}
			.product-name-section {
				position: fixed;
			}
			.particles-bg {
				position: fixed;
			}
			.floating-container {
				position: fixed;
			}
			.breadcrumbs {
				position: fixed;
			}
		}
	}


	main {
		position: relative;
		@media #{$lg} {
			// background-image: url('../img/section-bg-content-lg.png'), url('../img/bg-hero-lg-1.jpg');
			// background-size: auto 100vh, cover;
			// background-repeat: no-repeat, no-repeat;
			// background-position: right top, center top;
			// background-attachment: fixed, fixed;
				background-image: url('../img/bg-hero-lg-1.jpg');
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center top;
				&:after {
					content: '';
					@include size(vw-calc(1227),100vh);
					@include fixed(0,0,auto,auto,0);
					background-image: url('../img/section-bg-content-lg.png');
					background-size: cover;
				}
			&.v1 {
				background: transparent;
				&:after {
					display: none;
				}
			}
			&.v2 {
				background-image: url('../img/bg-hero-lg-1.jpg');
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center top;
				&:after {
					display: none;
				}
			}
		}
		@media #{$md} {
			// padding-top: $headerSizeMobile;
			background-image: url('../img/bg-mobile.jpg');
			background-size: 100vw auto;
			background-repeat: no-repeat;
			background-position: center 10vw;
		}
		@media #{$sm} {
			// padding-top: $headerSizeMobile;
			padding-top: 0;
			background-image: url('../img/bg-mobile.jpg');
			background-size: 100vw auto;
			background-repeat: no-repeat;
			background-position: center -125vw;
			&.error404 {
				padding-top: 0;
			}
		}

		img {
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-o-user-select: none;
			user-select: none;
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
		}
		// &:after {
		// 	content:  '';
		// 	@include fixed(0,0,0,0,-1);
		// 	@include size(100%,100%);
		// 	background-image: url('../img/section-bg-lg.jpg');
		// 	background-size: cover;
		// 	background-position: 50% 50%;
		// 	@media #{$hg} {
		// 		background-image: url('../img/section-bg-hg.jpg');
		// 	}
		// 	@media #{$md} {
		// 		display: none;
		// 	}
		// 	@media #{$sm} {
		// 		display: none;
		// 	}
		// }
		&.legal {
			&:after {
				@media #{$lg} {
					background-image: url('../img/legal-bg-lg.jpg');
				}
				@media #{$hg} {
					background-image: url('../img/legal-bg-hg.jpg');
					background-position: 50% 50%;
				}
			}
		}

		section {
			// background: radial-gradient(ellipse at center, #064481 0%,#012a62 100%);
			background: transparent;
			@include size(auto,auto);
			z-index: 2;
            min-height: 100vh;
			@media #{$md} {
				min-height: 0;
			}
			@media #{$sm} {
				min-height: 0;
			}
			// @include max(100vw,100vh);
			@include max(100vw,none);
			// @include max(1920px,100vh);
			margin: 0 auto;
			padding-top: $headerSize;
			position: relative;
			display: flex;
			box-sizing: border-box;


			@media #{$lg} {
				padding: calc(4.3vw + #{vw-calc(70)}) 0;
			}
			@media #{$hg} {
				padding: calc(4.3vw + #{rem-calc(70)}) 0;
			}
			@media #{$md} {
				padding-top: 0;
				@include size(100%,auto);
				@include max(none,none);
			}
			@media #{$sm} {
				padding-top: 0;
				@include size(100%,auto);
				@include max(none,none);
			}

			* {
				color: #fff;
			}

			/* typography */
			h1 {
				position: relative;
				display: inline-block;
				sup {
					@include absolute(0,0,auto,auto,1);
					display: inline-block;
					font-size: 40% !important;
					transform: translate(50%,0) !important;
				}
			}


			p {
				text-align: justify;
				// font-size: 1.675vmin;
				font-size: $fontSizeDesktopS;
				@media #{$hg} {
					font-size: rem-calc(15);
				}
				@media #{$md} {
					// font-size: 3.5vw;
					font-size: $fontSizeTabletS;
				}
				@media #{$sm} {
					// font-size: 3.5vw;
					font-size: $fontSizeMobileS;
				}
				strong {
					// font-size: 2.25vmin;
					font-size: $fontSizeDesktopL;
					&.block {
						display: block;
						margin-bottom: 2vmin;
					}
					@media #{$hg} {
						font-size: rem-calc(20);
					}
					@media #{$md} {
						font-size: $fontSizeTabletM;
						&.block {
							display: block;
							margin-bottom: 2vw;
						}
					}
					@media #{$sm} {
						font-size: $fontSizeMobileL;
						&.block {
							display: block;
							margin-bottom: 2vw;
						}
					}
				}
				&.title {
					color: $colorTertiary;
					text-align: left;
					font-family: $globalFontBold;
					@media #{$lg} {
						font-size: $fontSizeDesktopL;
					}
					@media #{$hg} {
						font-size: $fontSizeHugeL;
					}
					@media #{$md} {
						font-size: $fontSizeTabletL;
					}
					@media #{$sm} {
						font-size: $fontSizeMobileL;
					}
				}
				&.sm {
					font-size: 14px !important;
					text-align: left;
				}
			}
			.h0 {
				font-size: 60px;
				font-family: $globalFontBold;
				letter-spacing: -1.5px;
				@media #{$md} {
					font-size: 6.5vw;
				}
				@media #{$sm} {
					font-size: 10.5vw;
				}
			}
			.h1 {
				font-size: 100px;
				font-family: $globalFontBold;
				letter-spacing: -1.5px;
				@media #{$md} {
					font-size: 8.5vw;
				}
				@media #{$sm} {
					font-size: 10.5vw;
				}
			}
			.h2 {
				// font-size: $fontSizeDesktopH2;
				// font-size: 56px;
				// font-size: vw-calc(40);
				font-size: vw-calc(45);
				// font-size: vw-calc(30);
				line-height: 100%;
				font-family: $globalFontBold;
				@media #{$lg} {
					letter-spacing: -1.5px;
					margin-bottom: 1rem;
				}
				@media #{$hg} {
					font-size: $fontSizeHugeH2;
				}
				@media #{$md} {
					font-size: $fontSizeTabletH2;
					margin-bottom: 1rem;
				}
				@media #{$sm} {
					// font-size: $fontSizeMobileH2;
					font-size: vw-mobile(36) !important;
				}
			}
			.h4 {
				// font-size: $fontSizeDesktopH2;
				font-size: 25px;
				// font-size: vw-calc(40);
				// font-size: vw-calc(25);
				line-height: 100%;
				// font-family: $globalFontBold;
				font-weight: bold;
				@media #{$lg} {
					// letter-spacing: -1.5px;
					font-size: 16px;
				}
				@media #{$hg} {
					font-size: $fontSizeHugeH2;
				}
				@media #{$md} {
					font-size: $fontSizeTabletH3;
				}
				@media #{$sm} {
					// font-size: $fontSizeMobileH2;
					font-size: vw-mobile(30) !important;
				}
			}
			sup {
				display: inline-block;
				font-size: 40% !important;
				transform: translate(-20%,-20%) !important;
				@media #{$md} {
					font-size: 60% !important;
					transform: translate(-25%,-30%) !important;
					// display: inline;
				}
				@media #{$sm} {
					font-size: 60% !important;
					transform: translate(-25%,-30%) !important;
					// display: inline;
					@supports (-webkit-touch-callout: none) {
						//  margin-left: -0.5px;
					}

				}
				@media not all and (min-resolution:.001dpcm)
				{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {

					margin-left: -0.5px;
				}}
			}

			/* common items */

			.trigger-legal,
			.trigger-next {
				@include absolute(auto,auto,vw-calc(60),50%,10);
				transform: translateX(-50%);
				font-size: vw-calc(20);
				color: #fff;
				font-weight: bolder;
				cursor: pointer;
				@include no-select();
				@include transition('short');
				@media #{$hg} {
					font-size: rem-calc(20);
					bottom: rem-calc(60);
				}
				&:after {
					content: '';
					@include absolute(auto,auto,0,50%,10);
					transform: translate(-50%,15px) rotate(45deg);
					@include size(6px,6px);
					border-right: 2px solid #fff;
					border-bottom: 2px solid #fff;
					animation: bouncing linear 1.25s;
					animation-iteration-count: infinite;
					@include transition('short');
				}
				&:hover {
					&:after {
						border-color: $colorTertiary;
					}
				}
				@media #{$md} {
					display: none;
				}
				@media #{$sm} {
					display: none;
				}
			}
			.trigger-next {
				// @media (min-aspect-ratio: 16/9) {
				@media (min-aspect-ratio: 1381/785) {
					display: none;
				}
			}

			.product {
				display: inline-block;
				@include size(45%,100%);
				// float: left;
			}
			.info {
				display: inline-flex;
				@include size(45%,calc(100vh - calc(#{$headerSize} + 4vw)));
				justify-content: center;
				align-items: center;
				// float: right;
			}
			.icons-set {
				display: flex;
				justify-content: space-between;
				figure {
					margin: 20px auto;
					padding: 0 8px;
					width: 25%;
					text-align: center;
					.icon {
						display: inline-flex;
						@include size(vw-calc(148),vw-calc(148));
						@include max(rem-calc(148),rem-calc(148));
						background-image: url('../img/icon-figure.png');
						background-size: cover;
						margin: 0 auto;
						display: flex;
						justify-content: center;
						align-items: center;
						filter: drop-shadow(6px 6px 43px #003978);
						img {
							@include max(50%,50%);
						}
					}

					.description {
						text-align: left;

						font-size: 80%;
						line-height: 100%;
					}
					figcaption {
						text-transform: uppercase;
						font-weight: bold;
						text-align: center;
						font-size: vw-calc(20);
						margin-top: vh-calc(27);
						@media #{$hg} {
							font-size: rem-calc(20);
						}
						@media #{$md} {
							font-size: $fontSizeTabletS;
						}
						@media #{$sm} {
							font-size: $fontSizeMobileS;
						}
					}
				}
				@media #{$md} {
					figure {
						.icon {
							@include size(vw-tablet(148),vw-tablet(148));
							@include max(rem-calc(148),rem-calc(148));
						}
					}
				}
				@media #{$sm} {
					display: block;
					text-align: center;
					figure {
						vertical-align: top;
						display: inline-block;
						width: 40%;
						.icon {
							@include size(vw-mobile(148),vw-mobile(148));
							@include max(rem-calc(148),rem-calc(148));
						}
					}
				}
			}

			/* section-specific rules */

			&.s0 {
				@include transition('short');
				// overflow-x: hidden;
				@media #{$lg} {
					background: url('../img/bg-hero-lg.png') right top no-repeat, url('../img/bg-hero-lg-1.jpg') left top repeat;
					background-size: auto 100%, cover;
                    // background-attachment: fixed;
					.cta-buy {
						@include absolute(auto,auto,0,0,100);
						transform: translate(50%,0);
					}
				}

				@media #{$hg} {
					background: url('../img/bg-hero-hg-1.jpg') center top repeat;
					background-size: cover;
				}
				@media #{$md} {
					padding-top: 565px;
					padding-bottom: 50px;
					.cta-buy {
						position: relative;
					}
				}
				@media #{$sm} {
					padding-top: 85vw;
					padding-bottom: 50px;
					.cta-buy {
						position: relative;
					}
				}
				@media (min-width: 1281px) and (min-aspect-ratio: 70 / 51) and (max-aspect-ratio: 293/160) {
					background: url('../img/bg-hero-lg.png') 115% top no-repeat, url('../img/bg-hero-lg-1.jpg') left top repeat;
					background-size: auto 100%, cover;
					.wrapper {
						padding-left: 6vw;
					}
				}

				.head-mobile {
					@include absolute(0,auto,auto,50%,1);
					@include size(1100px,565px);
					transform: translateX(-50%);
					@media #{$sm} {
						@include size(vw-mobile(1100),vw-calc(565));
					}
				}
				.product-name {
					@include relative_auto;
					@include size(48vw,auto);
					@include max(922px,205px);
					@media #{$md} {
						@include relative_auto();
						@include size(auto,auto);
						@include max(100%,none);
					}
					@media #{$sm} {
						@include relative_auto();
						@include size(auto,auto);
						@include max(100%,none);
					}
				}
				.mobile-product {
					margin-left: calc(calc(100vw - #{vw-tablet(800)}) / -2);
				}
				.content {
					text-align: right;
					@include size(55%,auto);
					p {
						display: inline-block;
						@include size(23vw,auto);
						@include max(445px,none);
						// text-align: left;
						// font-size: vw-calc(15);
						margin-top: 2.6vw;
						margin-bottom: 3.3vw;
						line-height: 1.3;
						letter-spacing: -0.3px;
						strong {
							font-family: $globalFontBold;
							line-height: 1.2;
						}
						@media #{$hg} {
							margin-top: rem-calc(20);
							margin-bottom: rem-calc(20);
						}
						@media #{$md} {
							margin-top: 8.5vw;
							margin-bottom: 6.3vw;
							@include size(100%,auto);
							@include max(100%,none);
						}
						@media #{$sm} {
							margin-top: 8.5vw;
							margin-bottom: 6.3vw;
							@include size(100%,auto);
							@include max(100%,none);
						}
					}
					.start-icons {
						@include size(vw-calc(445),auto);
						@include max(445px,none);
						@media #{$md} {
							@include size(vw-tablet(684),auto);
							@include max(684px,none);
						}
						@media #{$sm} {
							@include size(vw-mobile(684),auto);
							@include max(100%,none);
						}
					}
					@media #{$md} {
						width: 100%;
						text-align: center;

					}
					@media #{$sm} {
						width: 100%;
						text-align: center;
					}
				}
			}

			&.s1 {

			}
			&.s2 {

				@media #{$md} {
					padding-bottom:	rem-calc(80);
					.steps-slider {
						padding-bottom: rem-calc(150);
						.slick-slide {
							text-align: center;
							p {
								text-align: center !important;
							}
						}
						.slick-arrow {
							@include size(rem-calc(45),rem-calc(45));
							top: auto;
							bottom: rem-calc(100);
							left: 50%;
							&.slick-prev {
								transform: translateX(-150%);
								&:before {
									left: -340%;
									text-align: right;
									content: 'Poprzedni\Akrok';
								}
							}
							&.slick-next {
								transform: translateX(50%);
								&:before {
									left: auto;
									right: -340%;
									content: 'Następny\Akrok';
								}
							}
							&:before {
								width: 300%;
								bottom: auto;
								transform: translateY(-50%);
    							top: 50%;
							}
						}
					}
				}
				@media #{$sm} {
					padding-bottom:	rem-calc(80);
					.steps-slider {
						padding-bottom: rem-calc(150);
						.slick-slide {
							text-align: center;
							p {
								text-align: center !important;
							}
						}
						.slick-arrow {
							@include size(rem-calc(45),rem-calc(45));
							bottom: rem-calc(100);
							left: 50%;
							&.slick-prev {
								transform: translateX(-150%);
								&:before {
									left: -100px;
									text-align: right;
									content: 'Poprzedni\Akrok';
								}
							}
							&.slick-next {
								transform: translateX(50%);
								&:before {
									left: auto;
									right: -100px;
									content: 'Następny\Akrok';
								}
							}
							&:before {
								width: 200%;
								bottom: auto;
								transform: translateY(-50%);
    							top: 50%;
							}
						}
					}
				}
				.important-box {
					padding: 25px;
					border-radius: 20px;
					background: #fff;
					display: flex;
					justify-content: stretch;
					align-items: center;
					box-shadow: 0px 12px 43px 0px rgba(2, 35, 79, 0.7);
					div {
						width: 50%;
						p {
							margin: 0;
						}
						.title {
							margin-bottom: 10px;
						}
						.description {
							color: #00295c;
						}
					}
					@media #{$md} {
						display: block;
						div {
							width: 100%;
							img {
								display: block;
								max-width: vw-tablet(326);
								margin: 25px auto 0;
							}
							.title {
								text-align: center;
								margin-bottom: vw-tablet(35);
							}
						}
					}
					@media #{$sm} {
						display: block;
						div {
							width: 100%;
							img {
								display: block;
								max-width: vw-mobile(326);
								margin: 25px auto 0;
							}
							.title {
								text-align: center;
								margin-bottom: vw-mobile(35);
							}
						}
					}
				}
			}

            &.s3 {
				@media #{$md} {
					padding-bottom: 10vw;
				}
				@media #{$sm} {
					padding-bottom: 15vw;
				}
                &.big-box {
					@media #{$lg} {
						padding-top: 15vw;
					}
                    .page-content {
                        @media #{$lg} {
                            width: 70%;
							margin-left: auto;
							margin-right: 0;
                        }
                    }

					@media #{$lg} {
						.cta-buy {
							// @include absolute(auto,auto,0,auto,100);
							@include absolute(auto,auto,0,auto,100);
							&.v1 {
								left: 0;
								transform: translate(50%,0);
							}
							&.v2 {
								right: 0;
								transform: translate(-50%,0);
							}
						}
					}
					@media only screen and (min-width: 1300px) and (max-width: 1600px) {
						.cta-buy {
							// bottom:
						}
					}
					@media #{$sm} {
						.mobile-center {
							text-align: center;
						}
						.cta-buy {
							position: relative;
							margin: 2rem auto;
							&.lg-only {
								display: none;
							}
						}
					}
					@media #{$md} {
						.big-box-img {
							max-width: 40vw;
							margin-left: auto;
							margin-right: auto;
							display: inline-block;
						}
						.mobile-center {
							text-align: center;
						}
						.cta-buy {
							position: relative;
							margin: 2rem auto;
							&.lg-only {
								display: none;
							}
						}
					}
                }
				.arrow-1 {
					@include absolute(50%,auto,auto,0,10);
					transform: translate(0,-50%);
					@media #{$lg} {
						@include size(vw-calc(138),auto);
						@include max(138px,none);
					}
					@media #{$sm} {
						left: 1rem;
						transform: translate(0,-100%);
						width: 20vw;
					}
					@media #{$md} {
						left: 50%;
						transform: translate(-250%,-100%);
						width: 10vw;
					}
				}
            }

			&.s4 {
				.items-slider {
					.slick-dots {
						bottom: -5rem;
						li {
							@include size(20px,20px);
							background: transparent;
							border: 2px solid #fff;
							border-radius: 100px;
							margin: 0 1rem;
							button {
								opacity: 0;
							}
							&.slick-active {
								background: #fff;
							}
						}
					}
				}
				.items-list {
					list-style: none;
					counter-reset: special-counter;
					li {
						position: relative;
						counter-increment: special-counter;
						font-size: 1.7vmin;
						padding-left: 20px;
						margin-bottom: 10px;
						@media #{$hg} {
							font-size: rem-calc(15);
						}
						@media #{$md} {
							font-size: $fontSizeTabletS;
						}
						@media #{$sm} {
							font-size: $fontSizeMobileS;
						}
						&:before {
							content: counter(special-counter);
							@include size(30px,30px);
							background: $colorTertiary;
							border-radius: 100px;
							color: #fff;
							  font-weight: bold;
							  display: inline-flex;
							  justify-content: center;
							  align-items: center;
							  margin-right: 15px;
							  @include absolute(50%,auto,auto,0,5);
							  transform: translate(-30px,-50%);
						}
					}
				}
				table {
					&.table-v1 {
						border-collapse: collapse;
						width: 100%;
						sup {
							font-size: 55% !important;
						}
						tr {
							padding: 13px 25px;
							td {
								@media #{$lg} {
									padding: 7px 25px 11px;
									&:first-of-type {
										border-radius: 15px 0 0 15px;
									}
									&:nth-of-type(2) {

									}
									&:last-of-type {
										border-radius: 0 15px 15px 0;
									}
								}
								@media #{$md} {
									font-size: $fontSizeTabletS;
									padding: 3vw;
								}
								@media #{$sm} {
									font-size: $fontSizeMobileS;
									padding: 2.5vw 2vw;
								}
							}
						}
						thead {
							tr {
								th {
									font-size: 1.3vmin;
									padding-bottom: 10px;
									@media #{$hg} {
										font-size: rem-calc(15);
									}
									@media #{$md} {
										font-size: $fontSizeTabletS;
									}
									@media #{$sm} {
										// font-size: $fontSizeMobileS;
										font-size: 2.6vw;

									}
								}

							}
						}
						tbody {
							tr:not(.spacer) {
								background: #0f5995;
								filter: drop-shadow(0 11px 24px rgba(2, 31, 73, 0.28));
								td {
									font-size: 1.3vmin;
									&:first-of-type {
										border-radius: 15px 0 0 15px;
									}
									&:nth-of-type(2) {
										border-left: 1px solid #094884;
										border-right: 1px solid #094884;
									}
									&:last-of-type {
										border-radius: 0 15px 15px 0;
									}
									@media #{$md} {
										font-size: $fontSizeTabletS;
									}
									@media #{$sm} {
										font-size: $fontSizeMobileS;
									}
								}
							}
							tr.spacer {
								padding: 0;
								td {
									padding: 5px;
								}
							}
						}
					}
				}
				.porada {
				 margin-top:50px;
				}
				#porada1 .table-v1 {
					margin-top:30px;
				}
				@media #{$lg} {
					.porada {
						ul {
							li {
								font-size: $fontSizeDesktopS;
							}
						}
					}
				}
				@media #{$hg} {
					.porada {
						ul {
							li {
								font-size: $fontSizeHugeS;
							}
						}
					}
				}
				@media #{$md} {
					.porada {
						margin: vw-tablet(60) auto;
						ul {
							li {
								font-size: $fontSizeTabletS;
							}
						}
					}
				}
				@media #{$sm} {
					margin-top: -70px;
                    .porada {
						margin: vw-mobile(20) auto vw-mobile(60);
                        padding-top: 100px;
						ul {
							li {
								font-size: $fontSizeMobileS;
							}
						}
                        &#porada1 {
                            padding-top: 75px;
                            margin-top: vw-mobile(20);
                        }
					}
				}
			}

			&.s5 {
				@media #{$md} {
					padding-bottom:	rem-calc(80);
				}
				@media #{$sm} {
					padding-bottom:	rem-calc(80);
				}
				.contact-details {
					display: block;
					width: 100%;
					text-align: left;
					ul {
						list-style: none;
						padding: 0;
						margin-top: vh-calc(60);
						li {
							display: flex;
							justify-content: flex-start;
							// align-items: flex-start;
							align-items: center;
							width: 100%;
							margin-bottom: 2.0vmin;
							@media #{$hg} {
								margin-bottom: 25px;
							}
							.ico-box {
								width: 45px;
								text-align: left;
							}
							svg,.ico {
								display: inline-block;
								margin: 3px 0 0 0;
								color: #fff;
							}
							p {
								display: inline-block;
								text-align: left;
								margin: 0;
								// font-size: 18px;
							}
							&:nth-of-type(1) {
								align-items: flex-start;
							}
						}
					}
				}
			}

			&.s6 {
				background-color: #fff;
                * {
					color: #0c57b0;
				}

				@media #{$lg} {
                	z-index: 9999;
				}

                min-height: 0 !important;

				@media #{$lg} {
					background-size: 100% auto;
					background-repeat: no-repeat;
					padding: 3rem 0 0;
				}

				@media #{$hg} {
					// background-image: url('../img/legal-bg-hg.png');
					// background-position: 50% 50%;
					// background-size: 2560px;
					padding: 3rem 0 0;
					//&:after {
					//	content: '';
					//	@include size(100%,30vh);
					//	@include absolute(auto,auto,0,0,1);
					//	background: #fff;
				//	}
				}
				@media #{$md} {
					background: #fff;
					padding-top: 5vw;
					// &:before {
					// 	content: '';
					// 	@include absolute(-10vw,auto,auto,0,10);
					// 	@include size(100%,10vw);
					// 	background-image: url('../img/legal-bg-mobile.png');
					// 	background-size: cover;
					// }
				}
				@media #{$sm} {
					background: #fff;
					padding-top: 5vw;
					// &:before {
					// 	content: '';
					// 	@include absolute(-10vw,auto,auto,0,10);
					// 	@include size(100%,10vw);
					// 	background-image: url('../img/legal-bg-mobile.png');
					// 	background-size: cover;
					// }
				}
				.legal-box {
					background: #fff;
					@media #{$lg} {
						&:before {
							content: '';
							// @include absolute(0,auto,auto,50%,-1);
							@include size(100vw,100%);
							@include max(1920px,none);
							// transform: translateX(-50%);
							background: #fff;
						}
						&:after {
							content: '';
							@include absolute(#{vw-calc(-153)},auto,auto,50%,-1);
							@include size(100vw,#{vw-calc(153)});
							@include max(1920px,none);
							transform: translateX(-50%);
							// background-image: url('../img/legal-bg-lg.png');
							background-position: center top;
							background-size: cover;
							background-repeat: no-repeat;
						}
					}
					@media #{$hg} {
						&:before {
							content: '';
							// @include absolute(0,auto,auto,50%,-1);
							@include size(100vw,100%);
							@include max(3370px,none);
							// transform: translateX(-50%);
							background: #fff;
						}
						&:after {
							content: '';
							@include absolute(#{vw-calc(-153)},auto,auto,50%,-1);
							@include size(100vw,#{vw-calc(153)});
							@include max(3370px,none);
							transform: translateX(-50%);
							background-image: url('../img/legal-bg-hg.png');
							background-position: center top;
							background-size: cover;
							background-repeat: no-repeat;
						}
					}
					@media (min-aspect-ratio: 160/65) {
						&:after {
							display: none;
						}
					}

					// @include absolute(auto,auto,0,0,10);
					@include size(100%,auto);
					p,sup {
						color: $colorPrimary !important;
						strong {
							font-size: inherit;
						}
					}
					box-sizing: border-box;
					@include transition('short');
					font-family: $globalFontLight;
					&.show {
						transform: translateY(0);
					}
					p {
						color: #003068;
						&.small {
							text-align: justify;
							@media #{$lg} {
								font-size: $fontSizeDesktopXS;
							}
							@media #{$hg} {
								font-size: $fontSizeHugeXS;
							}
							@media #{$md} {
								font-size: 1vw;
							}
							@media #{$sm} {
								font-size: $fontSizeMobileXS;
							}
							sup {
								font-size: 60% !important;
							}
						}
						&.smallest {
							font-size: rem-calc(14);
							font-weight: bold;
						}
						&.big {
							font-size: 2.7vmin;
							text-align: center;
							@media #{$hg} {
								font-size: rem-calc(26);
							}
							@media #{$md} {
								font-size: 1.5vw;
							}
							@media #{$sm} {
								font-size: 3.5vw;
							}
						}
					}
					hr {
						background: $colorSecondary;
						margin: 2vmin auto;
						border-style: solid;
						height: 1px;
						border: 0;
						@media #{$hg} {
							margin: rem-calc(25) auto;
						}
					}
					.btn {
						padding: 7px 50px 11px 30px;
						@media #{$md} {
							// display: inline-block;
							// margin: 15px auto;
							box-sizing: border-box;
							display: block;
							width: 100%;
							margin: 5px auto;
						}
						@media #{$sm} {
							display: block;
							width: 100%;
							margin: 5px auto;
							box-sizing: border-box;
						}
						border: 1px solid $colorSecondary;
						border-radius: 100px;
						color: $colorSecondary;
						text-decoration: none;
						@include transition('short');
						position: relative;
						cursor: pointer;
						margin-left: rem-calc(16);
						@media #{$lg} {
							margin: 1rem 0 1rem 1rem;
						}
						&:after {
							content: '';
							@include absolute(50%,25px,auto,auto,10);
							transform: translateY(-50%) rotate(45deg);
							@include size(10px,10px);
						}
						&.arrow-down {
							&:after {
								transform: translateY(-75%) rotate(45deg);
								border-right: 1px solid $colorSecondary;
								border-bottom: 1px solid $colorSecondary;
							}
						}
						&.arrow-right {
							&:after {
								border-right: 1px solid $colorSecondary;
								border-top: 1px solid $colorSecondary;
							}
						}
						&.close {
							&:before {
								content: '';
								@include absolute(50%,25px,auto,auto,10);
								@include size(16px,1px);
								background: $colorSecondary;
								transform: translate(50%,-50%) rotate(-45deg);
							}
							&:after {
								@include absolute(50%,25px,auto,auto,10);
								@include size(16px,1px);
								background: $colorSecondary;
								transform: translate(50%,-50%) rotate(45deg);
							}
							&:hover {
								&:before,
								&:after {
									background: #fff;
								}
							}
						}
						&:hover {
							background: $colorSecondary;
							color: #fff;
							&:before {
								border-color: #fff;
							}
							&:after {
								border-color: #fff;
							}
						}
					}
					.row {
						display: flex;
						margin-bottom: rem-calc(25);
						align-items: flex-end;
						&.btns {
							position: relative;
							justify-content: space-between;
							align-items: flex-start;
							@media #{$lg} {
								align-items: center;
							}
						}
						&.legals {
							align-items: flex-start;
							position: relative;
							.col {
								@media #{$lg} {
									padding-top: 2rem;
									padding-right: 2rem;
								}
							}
							&:after {
								content: '';
								@include absolute(0,auto,auto,1rem,5);
								@include size(157px,1px);
								background: $colorPrimary;
							}
							@media #{$md} {
								padding-top: 10px;
							}
							@media #{$sm} {
								padding-top: 10px;
							}
						}
						.col {
							display: inline-flex;
							&.half {
								width: 50%;
							}
							&.full {
								width: 100%;
							}
							&.has-buttons {
								justify-content: flex-end;
							}
							@media #{$md} {
								width: 100% !important;
								display: block;
								&.has-buttons {
									// display: flex;
									// justify-content: space-between;
									.btn {
										margin-left: auto;
										margin-right: auto;
									}
								}
								&.half {
									width: 100%;
								}
							}
							@media #{$sm} {
								width: 100% !important;
								display: block;
								&.has-buttons {
									display: flex;
									justify-content: space-between;
									.btn {
										margin-left: auto;
										margin-right: auto;
									}
									@media #{$sm} {
										flex-direction: column;
									}
								}
								&.half {
									width: 100%;
								}
							}
						}
					}
					@media #{$md} {
						@include relative_auto();
						transform: translateY(0);
						padding: 50px 0;
						.row {
							display: block;
						}
					}
					@media #{$sm} {
						@include relative_auto();
						transform: translateY(0);
						padding: 50px 0;
						.row {
							display: block;
						}
					}
					.btns{
						display: flex;
						flex-wrap: wrap;

						.col-8{
							@media #{$lg} {
								width: 71%;
								margin-left: 4%;
							}
						}
						.col-4{
							@media #{$lg} {
								width: 25%;
							}
						}
						.flex-column{
							display: flex;
							flex-direction: column;
						}
						p{
							@media #{$lg} {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			&.s7 {

				@media #{$lg} {
					background-image: url('../img/flying-bg-lg.png'), url('../img/bg-gradient-lg.jpg');
					background-position: center top, center top;
					background-size: 100% auto, cover;
				}
				@media #{$md} {
					padding-bottom: 10vw;
				}
				@media #{$sm} {
					padding-bottom: 15vw;
				}


				.wrapper {
					.page-content {
						width: 100%;
						margin: 0 auto;
						text-align: center;
					}
				}
				.subtitle {
					line-height: 120%;
					margin: 2rem auto 3rem;
				}

				.row.slick-slider {
					margin: 0 auto;
				}

			}

			&.s8 {
				@media #{$lg} {
					background-image: url('../img/bg-article-lg.jpg');
					background-size: 100%;
					background-position: center top;
					background-repeat: no-repeat;
					background-color: #022557;
				}

				@media #{$md} {
					padding-bottom: 15vw;
				}
				@media #{$sm} {
					padding-bottom: 15vw;
				}


				z-index: 5;
				@media #{$hg} {
					background-size: 1920px auto;
				}
				.wrapper {
					// width: 100%;
					@media #{$hg} {
						// padding: 0 5rem;
					}
					@media only screen and (min-width: 1500px) and (max-width: 1920px) {
						// padding: 0 6vw;
					}

					.sticky {
						position: sticky;
						top: 40vh;
						display: inline-block;
						@include size(60px,60px);
					}

					.headline.color {
						display: inline-block;
						position: relative;
						text-align: left;
						margin-top: 2rem;
						margin-bottom: 3rem;
						padding: 0.5rem 1rem;
						font-family: $globalFontBold;

						&:before {
							content: '';
							@include absolute(3px,auto,auto,0,-1);
							@include size(500px,100%);
							background: linear-gradient(90deg, $colorTertiary, $colorTertiary 40%, rgba(7,7,7,0) 100%);
						}

						@media #{$sm} {
							&:before {
								width: 80vw;
							}
						}
					}

					.article-content {
						p {
							font-size: 16px;
						}



						.h2.lg {
							font-family: $globalFontBold;
							@media #{$lg} {
								font-size: vw-calc(26);
								letter-spacing: 0;
							}
							@media #{$sm} {
								text-align: left;
							}
						}

						img {
							display: block;
							margin: auto auto 1rem;
						}

						.article-title {
							font-family: $globalFontBlack;
							line-height: 100%;
							@media #{$lg} {
								font-size: vw-calc(60);
								margin-bottom: 60px;
							}
							@media #{$md} {
								font-size: vw-mobile(25);
								margin-bottom: 50px;
							}
						}

						.figures-container {
							display: flex;
							justify-content: space-between;
							figure.icon {
								vertical-align: top;
								.img-container {
									display: flex;
									justify-content: center;
									align-items: center;
									height: 120px;
								}
								p {
									display: flex;
									align-items: center;
									justify-content: center;
									height: 100px;
								}
							}
						}

						figure.icon {
							@media #{$lg} {
								display: inline-block;
								width: 18%;
								margin: 1rem auto;
								p {
									text-align: center;
									line-height: 100%;
								}
							}
						}

						@media #{$md} {
							.figures-container {
								display: block;
								figure.icon {
									display: inline-block;
									width: 48%;
									margin: 1rem auto;
									p {
										text-align: center;
									}
									.img-container {
										height: 160px;
										margin-bottom: 1.5rem;
									}
								}
							}
						}
						@media #{$sm} {
							.figures-container {
								display: block;
								figure.icon {
									display: inline-block;
									width: 48%;
									margin: 1rem auto;
									p {
										text-align: center;
									}
									.img-container {
										height: 160px;
										margin-bottom: 1.5rem;
									}
								}
							}
						}

						.quote {
							text-align: center;
							margin: 4rem auto;
							padding: 0 6rem;
							position: relative;
							&:before,
							&:after {
								@include absolute(50%,auto,auto,auto,1);
								transform: translate(0,-65%);
								font-family: $globalFontLight;
								font-size: 100px;
							}
							&:before {
								content: '(';
								left: 0;
							}
							&:after {
								content: ')';
								right: 0;
							}

							@media #{$sm} {
								padding-left: 2rem;
								padding-right: 2rem;
								font-size: 10px;
							}
						}

						@media #{$sm} {
							padding-left: 2rem;
							padding-right: 2rem;
						}

					}

					.related {
						margin: 0 -30px;
						a {
							border: 1px solid #dadada;
							padding: 0;
							text-align: left;
							display: block;
							width: 100%;
							margin: auto;
							img {
								width: 100%;
								margin-bottom: 2rem;
							}
							h3 {
								font-size: 1.5rem;
								padding: 0 1rem;
							}
							p {
								padding: 0 1rem;
							}
							.link {
								font-weight: bolder;
							}
						}
					}
					.has-articles-inside {
						margin: 0 -30px;
						width: calc(100% + 60px);
						div {
							@media #{$sm} {
								margin-bottom: 2rem !important;
							}
						}
						a {
							height: 100%;
							margin: 0 auto;
						}
						img {
							margin-top: 0;
						}
					}
				}
				.article-arrow {
					// @include absolute(50%,auto,auto,auto,10);
					// transform: translateY(-50%);
					background: #fff;
					border-radius: 100px;
					display: flex;
					justify-content: center;
					align-items: center;
					@include size(60px,60px);
					&::after {
						@include size(15px,15px);
						// transform: translate(-50%,-50%);
						transform-origin: 50% 50%;
						transform: translate(0,-4px);
					}
					&::before {
						content: '';
						@include absolute(auto,auto,0,0,5);
						@include size(150%,auto);
						color: #fff;
						text-transform: uppercase;
						font-size: 20px;
						line-height: 100%;
						text-align: left;
						transform: translateY(150%);
						font-family: $globalFont;
						opacity: 1;
						@media #{$hg} {
							font-size: rem-calc(15);
						}
						@media #{$md} {
							font-size: 16px;
						}
						@media #{$sm} {
							font-size: $fontSizeMobileS;
						}
						@media all and (-ms-high-contrast:none)	{
							top: 150%;
							left: 0;
						}
					}
					&.prev {
						left: rem-calc(-80);
						&:after {
							content: url('../img/arrow-prev.png');
							margin-right: 6px;
							@media all and (-ms-high-contrast:none)	{
								margin-left: 12px;
							}
						}
						&:before {
							content: 'Poprzedni\A artykuł';
							white-space: pre;
						}
					}
					&.next {
						right: rem-calc(-80);
						&::after {
							content: url('../img/arrow-next.png');
							margin-left: 6px;
							@media all and (-ms-high-contrast:none)	{
								margin-left: 14px;
							}
						}
						&::before {
							content: 'Następny\A artykuł';
							white-space: pre;
						}
					}
				}
			}

			&.s10 {
				@media #{$md} {
					padding-bottom: 10vw;
				}
				@media #{$sm} {
					padding-bottom: 15vw;
				}
				@media #{$lg} {
					background-image: url('../img/flying-bg-lg.png'), url('../img/bg-gradient-lg.jpg');
					background-position: center top, center top;
					background-size: 100vw auto, cover;
					background-repeat: no-repeat;
					background-attachment: fixed;
				}
				@media #{$hg} {
					background-size: 1920px auto, 1920px auto;
				}
				[itemprop="mainEntity"] {
					padding: 1rem 0;
					[itemprop="name"] {
						position: relative;
						display: block;
						padding-right: 50px;
						cursor: pointer;
						&:after {
							content: '';
							@include absolute(50%,1rem,auto,auto,10);
							transform: translate(0,-50%) rotate(45deg);
							transform-origin: 50% 50%;
							@include size(20px,20px);
							border-right: 1px solid #fff;
							border-bottom: 1px solid #fff;
						}
						&.active {
							&:after {
								transform: translate(0,-50%) rotate(-135deg);
							}
						}
					}
					&:not(:first-of-type) {
						border-top: 1px solid rgba(255,255,255,0.5);
					}
					[itemprop="acceptedAnswer"] {
						display: none;
						padding: 2rem 0;
					}
				}
			}

			&.error404 {
				@include size(100vw,100vh);
				background-image: url('../img/bg404.jpg');
				background-size: cover;
				background-position: center top;
				display: flex;
				justify-content: center;
				align-items: center;
				.wrapper {
					.page-content {
						width: 100%;
						margin: 0 auto;
						text-align: center;
						.error-content {
							text-align: center;
							h1,h2,p {
								text-align: center;
							}
							h1 {
								margin: 0 auto;
							}
							.h2 {
								display: block;
								@media #{$lg} {
									font-size: 42px;
									&:first-of-type {
										margin-bottom: 60px;
									}
								}
								a {
									text-decoration: none;
									transition: all 200ms;
								}
							}
						}
					}
				}
			}
		}

		/* fixed elements */

		#particles-js {
			@include fixed(20%,auto,auto,0,15);
			@include size(35%,80vh);
			@media #{$hg} {
				@include size(rem-calc(740),rem-calc(454));
				@include max(rem-calc(740),none);
				left: 50%;
				transform: translateX(-960px);
			}
			@media #{$md} {
				display: none;
			}
			@media #{$sm} {
				display: none;
			}
		}
		.product-image {

			@include absolute(18vw,auto,auto,0,10);
			// @include fixed(18vw,auto,auto,0,10);

			// @include size(auto,66vh);
			@include size(auto,62vh);

			@include max(none,591px);
			@media #{$hg} {
				left: 50%;
				// transform: translateX(-960px);
				transform: translateX(-1060px);
				top: 330px;
			}
			&.hide-lg-only {
				@media #{$lg} {
					display: none;
				}
				@media #{$hg} {
					display: block;
				}
			}
		}
		.product-name {
			// @include fixed(auto,auto,-50px,0,10);
			@include fixed(auto,auto,0,0,10);
			@include size(25vw,auto);
			@include max(none,15vh);
		}
		.product-name-section {
			// @include fixed(calc(#{$headerSize} + 4vw),auto,auto,8vw,10);
			@include absolute(calc(#{$headerSize} + 4vw),auto,auto,8vw,10);
			@include size(auto,auto);
			@include max(rem-calc(492),15vh);
			visibility: visible;
			opacity: 1;
			transform: translateY(0%);
			@include transition('short');
			@media #{$hg} {
				left: 50%;
				transform: translate(-750px,0%);
			}

		}

		.particles-bg {
			// @include fixed(15vw,auto,auto,0,5);
			@include absolute(15vw,auto,auto,0,5);
			@include size(vw-calc(740),vw-calc(454));
			// animation-iteration-count: infinite;
			@media #{$hg} {
				@include size(rem-calc(740),rem-calc(454));
				@include max(rem-calc(740),none);
				left: 50%;
				transform: translateX(-960px);
			}
		}

		/* all floating flowers */

		.floating-container {
			// @include fixed(0,auto,auto,0,11);
			@include absolute(0,auto,auto,0,11);
			@include size(vw-calc(800),100vh);
			@include max(740px,980px);
			@media #{$hg} {
				left: 50%;
				transform: translateX(-940px);
			}
			visibility: visible;
			opacity: 1;
			margin-top: 0;
			@include transition('short');

			.floating-object {
				visibility: visible;
				opacity: 1;
				margin-top: 0;
				@include transition('short');

				&.obj-1 {
					animation: floating1 linear 3.25s;
					animation-iteration-count: infinite;
					@include absolute(auto,auto,47%,15%,15);
					@include size(vw-calc(68),auto);
					@media #{$hg} {
						@include size(rem-calc(68),auto);
						left: 10%;
    					bottom: 40.5%;
					}
				}
				&.obj-2 {
					@include absolute(auto,auto,4.8vw,28.4vw,15);
					animation: floating1 linear 1.55s;
					animation-iteration-count: infinite;
					@include size(vw-calc(79),auto);
					@media #{$hg} {
						@include size(rem-calc(79),auto);
						left: 58%;
					}
					animation: floating1 linear 23.0s;
					animation-iteration-count: infinite;
				}
				&.obj-3 {
					@include absolute(auto,auto,9vw,2vw,15);
					animation: floating1 linear 3.85s;
					animation-iteration-count: infinite;
					@include size(vw-calc(78),auto);
					@media #{$hg} {
						@include size(rem-calc(78),auto);
						left: 3%;
    					bottom: 8%;
					}
				}
				&.obj-4 {
					@include absolute(auto,auto,2vw,6vw,15);
					animation: floating1 linear 3.25s;
					animation-iteration-count: infinite;
					@include size(vw-calc(114),auto);
					@media #{$hg} {
						@include size(rem-calc(114),auto);
					}
				}
				&.obj-5 {
					@include absolute(auto,auto,12.8vw,32vw,15);
					animation: floating1 linear 3.25s;
					animation-iteration-count: infinite;
					@include size(vw-calc(65),auto);
					@media #{$hg} {
						@include size(rem-calc(65),auto);
						left: 78%;
						bottom: 16%;
					}
				}
				&.obj-6 {
					@include absolute(auto,auto,29vw,2vw,15);
					animation: floating1 linear 2.55s;
					animation-iteration-count: infinite;
					@include size(vw-calc(60),auto);
					@media #{$hg} {
						@include size(rem-calc(60),auto);
						left: 2%;
						bottom: 51%;
					}
				}
				&.obj-7 {
					@include absolute(auto,auto,29.9vw,25.2vw,15);
					animation: floating1 linear 3.25s;
					animation-iteration-count: infinite;
					@include size(vw-calc(109),auto);
					@media #{$hg} {
						@include size(rem-calc(109),auto);
						left: 64%;
						bottom: 53%;
					}
				}
				&.obj-8 {
					@include absolute(auto,auto,29vw,6vw,15);
					animation: floating1 linear 3.05s;
					animation-iteration-count: infinite;
					@include size(vw-calc(51),auto);
					@media #{$hg} {
						@include size(rem-calc(51),auto);
						left: 15%;
						bottom: 53%;
					}
				}
				&.obj-9 {
					@include absolute(auto,auto,13vw,8vw,15);
					animation: floating1 linear 3.05s;
					animation-iteration-count: infinite;
					@include size(vw-calc(63),auto);
					@media #{$hg} {
						@include size(rem-calc(63),auto);
						left: 17%;
						bottom: 16%;
					}
				}
				&.obj-10 {
					@include absolute(auto,auto,8vw,6vw,15);
					animation: floating1 linear 3.95s;
					animation-iteration-count: infinite;
					@include size(vw-calc(55),auto);
					@media #{$hg} {
						@include size(rem-calc(55),auto);
						left: 12%;
						bottom: 6%;
					}
				}
				&.obj-11 {
					@include absolute(auto,auto,18.5vw,0,15);
					// animation: floating1 linear 3.35s;
					animation-iteration-count: infinite;
					@include size(vw-calc(70),auto);
					@media #{$hg} {
						@include size(rem-calc(70),auto);
						left: -20px;
    					bottom: 28%;
					}
				}
				&.obj-12 {
					@include absolute(auto,auto,14.7vw,27.9vw,15);
					animation: floating1 linear 2.75s;
					animation-iteration-count: infinite;
					@include size(vw-calc(37),auto);
					@media #{$hg} {
						@include size(rem-calc(37),auto);
						left: 70%;
    					bottom: 20%;
					}
				}
				&.obj-13 {
					@include absolute(auto,auto,18.1vw,33vw,15);
					animation: floating1 linear 2.55s;
					animation-iteration-count: infinite;
					@include size(vw-calc(49),auto);
					@media #{$hg} {
						@include size(rem-calc(49),auto);
						left: 81%;
						bottom: 27%;
					}
				}
				&.obj-14 {
					@include absolute(auto,auto,15.5vw,28.8vw,15);
					animation: floating1 linear 3.25s;
					animation-iteration-count: infinite;
					@include size(vw-calc(44),auto);
					@media #{$hg} {
						@include size(rem-calc(44),auto);
						left: 73%;
						bottom: 26%;
					}
				}
				&.obj-15 {
					@include absolute(auto,auto,39%,79%,15);
					animation: floating1 linear 3.55s;
					animation-iteration-count: infinite;
					@include size(vw-calc(32),auto);
					@media #{$hg} {
						@include size(rem-calc(32),auto);
						left: 83%;
						bottom: 31%;
					}
				}
				&.obj-16 {
					@include absolute(auto,auto,54%,73%,15);
					animation: floating1 linear 2.95s;
					animation-iteration-count: infinite;
					@include size(vw-calc(41),auto);
					@media #{$hg} {
						@include size(rem-calc(41),auto);
						left: 76%;
    					bottom: 48%;
					}
				}
			}
		}

		/* commong setup for slick sliders */

		.slick-slider {
			@include size(100%,auto);
			.slick-arrow {
				@include absolute(50%,auto,auto,auto,10);
				transform: translateY(-50%);
				background: #fff;
				border-radius: 100px;
				@include size(60px,60px);
				&::after {
					@include size(15px,15px);
					transform: translate(-50%,-50%);
					transform-origin: 50% 50%;
				}
				&::before {
					content: '';
					@include absolute(auto,auto,0,0,5);
					@include size(150%,auto);
					color: #fff;
					text-transform: uppercase;
					font-size: 1.5vmin;
					text-align: left;
					transform: translateY(150%);
					font-family: $globalFont;
					opacity: 1;
					@media #{$hg} {
						font-size: rem-calc(15);
					}
					@media #{$md} {
						font-size: $fontSizeTabletS;
					}
					@media #{$sm} {
						font-size: $fontSizeMobileS;
					}
					@media all and (-ms-high-contrast:none)	{
						top: 150%;
						left: 0;
					}
				}
				&.slick-prev {
					left: rem-calc(-80);
					&:after {
						content: url('../img/arrow-prev.png');
						margin-right: 6px;
						@media all and (-ms-high-contrast:none)	{
							margin-left: 12px;
						}
					}
					&:before {
						content: 'Poprzednia\Aporada';
						white-space: pre;
					}
				}
				&.slick-next {
					right: rem-calc(-80);
					&::after {
						content: url('../img/arrow-next.png');
						margin-left: 6px;
						@media all and (-ms-high-contrast:none)	{
							margin-left: 14px;
						}
					}
					&::before {
						content: 'Następna\Aporada';
						white-space: pre;
					}
				}
			}
			.slick-slide {
				outline: none !important;
				padding: 0 1rem;
				@media #{$lg} {
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}
		}

		/* temporary images for testing purposes */

		.temp {
			@include fixed(0,auto,auto,0,90);
			@include size(100%,auto);
			filter: invert(100);
			opacity: 0.4;
			display: none;
			transition: all 200ms;
			&.show-dev {
				display: block;
			}
			@media #{$hg} {
				max-width: 1920px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
		.debug-container {
			@include fixed(5px,5px,auto,auto,999999);
			@include size(auto,auto);
			@include max(350px,500px);
			padding: 0.5rem;
			background: yellow;
			color: #000;
			font-weight: bold;
			display: none;
			font-size: 10px;
			&.show {
				display: block;
			}
			@media #{$md} {
				@include fixed(auto,auto,0,0,90);
				@include size(100%,auto);
				@include max(100vw,none);
				padding: 5px;
				br {
					display: none;
				}
			}
			@media #{$sm} {
				@include fixed(auto,auto,0,0,90);
				@include size(100%,auto);
				@include max(100vw,none);
				padding: 5px;
				br {
					display: none;
				}
			}
		}
	}

}
/* custom */
.icons-set .slick-slide p.title {
	font-size:25px!important;
}
