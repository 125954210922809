body {
	background: $colorPrimary;
	overflow-x: hidden;
	font-family: $globalFont;
	padding: 0;
    margin: 0;
    @media #{$md} {
        max-width: 100vw;
        overflow-x: hidden;
        background: $colorSecondary;
    }
    @media #{$sm} {
        max-width: 100vw;
        overflow-x: hidden;
        background: $colorSecondary;
    }
    &[data-current-section="current6"] {
        @media #{$lg} {
            .product-name-section {
                max-height: 10vh;
            }
        }
    }

    .article-content {
        p {
            sup {
                font-size: 80% !important;
            }
        }
    }

    .h-subtitle {
        display: inline-block;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        margin-top: 1rem;
        margin-bottom: 3rem;
        padding: 0.5rem 2rem;
        font-family: $globalFontBold;

        @media #{$lg} {
            font-size: vw-calc(38);
        }

        &:before {
            content: '';
            @include absolute(50%,auto,auto,50%,-1);
            @include size(calc(100% + 4rem),100%);
            transform: translate(-50%,-47%);
            // background: linear-gradient(90deg, $colorTertiary, $colorTertiary 40%, rgba(7,7,7,0) 100%);
            background: $colorTertiary;
            background: linear-gradient(90deg, rgba(255,0,0,0) 0%, $colorTertiary 10%, $colorTertiary 90%, rgba(255,0,0,0) 100%);
        }

        sup {
            top: -1rem;
        }
    }

    /* body specific */

    &.articles {
        main {
            @media #{$md} {
                background-image: url('../img/flying-bg-lg.png'), url('../img/bg-gradient-lg.jpg');
                background-position: center top, center top;
                background-size: 100% auto, cover;
            }
            @media #{$sm} {
                background-image: url('../img/flying-bg-lg.png'), url('../img/bg-gradient-lg.jpg');
                background-position: center top, center top;
                background-size: 100% auto, cover;
            }
        }
    }
    @media #{$sm} {
        main {
            overflow-x: hidden;
        }
    }


    .head-mobile.page {
        // @include absolute(70px,auto,auto,50%,1);
        // @include size(1100px,418px);
        @include size(100vw,418px);
        // transform: translateX(-50%);
        background-image: url('../img/head-mobile-page.png');
        background-size: 1100px 418px;
        background-position: center top;
        @media #{$sm} {
            @include size(100vw,300px);
            background-size: 789px 300px;
            margin-bottom: 20px;
        }
    }
    .btn {
        padding: 7px 50px 11px 30px;
        @media #{$md} {
            display: inline-block;
            margin: 15px auto;
            box-sizing: border-box;
        }
        @media #{$sm} {
            display: block;
            width: 100%;
            margin: 5px auto;
            box-sizing: border-box;
        }
        border: 1px solid $colorSecondary;
        border-radius: 100px;
        color: $colorSecondary;
        text-decoration: none;
        @include transition('short');
        position: relative;
        &.equal {
            padding: 7px 30px 11px 30px;
            &:hover {
                background: $colorSecondary;
                color: #fff;
            }
        }
    }
	.cookiesNotification {
        @include fixed(auto,auto,0,0,9999999);
        @include size(100%,auto);
        background: #fff;
        padding: rem-calc(50) 0;
        .wrapper {
            @media #{$lg} {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
		.closeNotification {
			display: inline-block;
			cursor: pointer;
            font-family: $globalFont !important;
            border-color: #003068;
            @media #{$lg} {
                margin-left: 55px;
            }
            @media #{$sm} {
                margin-top: 30px;
            }
		}
		p {
			display: inline-block;
			margin: 0;
            font-family: $globalFontLight;
            color: #003068;
            @media #{$md} {
                // font-size: 3.5vw;
                font-size: $fontSizeTabletS;
            }
            @media #{$sm} {
                // font-size: 3.5vw;
                font-size: $fontSizeMobileS;
            }
        }
        a {
            color: inherit;
        }
		@media #{$sm} {
			text-align: center;
		}
    }
    #preloader {
        @include fixed(0,0,0,0,9999999999);
        @include size(100%,100%);
        background: radial-gradient(ellipse at center, #064481 0%,#012a62 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            // animation: preloader linear 5s;
            // animation-iteration-count: infinite;
            @include size(135px,auto);
        }
        @media #{$md} {
            img {
                max-width: 30vw;
            }
        }
        @media #{$sm} {
            img {
                max-width: 60vw;
            }
        }
    }
    .placeholder-overlay {
        display: none;
    }
    &.placeholder {
        overflow: hidden;
        section,
        header,
        img {
            filter: blur(20px);
            @media all and (-ms-high-contrast:none)	{
                opacity: 0.3;
            }
        }

        .placeholder-overlay {
            @include fixed(0,0,0,0,100000);
            @include size(100%,100%);
            // background: radial-gradient(ellipse at center, #064481 0%,#012a62 100%);
            background: rgba(1,56,121,0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                color: #fff;
                font-size: rem-calc(30);
            }
            @media all and (-ms-high-contrast:none)	{
                background: $colorSecondary;
            }
        }
    }

    .has-articles-inside {

        a {
            border: 1px solid #dadada;
            @include media-breakpoint-up(lg) {
                margin: 0 1rem;
            }
            padding: 0;
            text-align: left;
            display: block;
            text-decoration: none !important;
            @include size(100%,auto);
            @media #{$lg} {
                min-height: 500px;
            }
            @media #{$md} {
                height: auto;
                margin-bottom: 1rem;
            }
            @media #{$sm} {
                height: auto;
            }
        }
        img {
            width: 100%;
            margin-bottom: 2rem;
        }
        h3 {
            font-size: 20px;
            padding: 0 1rem;
            margin-bottom: 1rem;
        }
        p {
            font-size: 15px;
            padding: 0 1rem;
            @media #{$sm} {
                display: none;
            }
        }
        .link {
            font-weight: bolder;
        }
        .slick-slide {
            padding: 0;
        }
        .slick-dots {
            bottom: -4rem;
            li {
                @include size(15px,15px);
                border-radius: 100px;
                background: #fff;
                button {
                    opacity: 0;
                }
                &.slick-active {
                    background: $colorTertiary;
                }
            }
        }
        .slick-arrow {
            &:before {
                display: none;
            }
        }
        @include media-breakpoint-down(sm) {
            a {
                margin-bottom: 3rem;
            }
        }
    }

    .cta-buy {
        display: inline-block;
        border-radius: 100px;
        background: $colorTertiary;
        padding: 0.5rem 5rem 0.9rem 2rem;
        line-height: 100%;
        &:after {
            content: '';
            @include size(0.8rem,0.8rem);
            @include absolute(50%,0,auto,auto,1);
            transform: translate(-200%,-50%) rotate(45deg);
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
        }
        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }
    .cta-buy {
        // &:not(.relative) {
        //     @include absolute(auto,auto,0,0,30);
        //     transform: translate(50%,0);
        // }
        &.relative {
            position: relative;
        }
    }

	&.start {
		.product-name-section,
		.floating-container,
		.floating-object, {
			visibility: hidden;
			opacity: 0;
			margin-top: 100px;
        }
		section#start {
			opacity: 1;
        }
        .particles-bg,
        #particles-js {
            opacity: 0;
        }
	}
	&:not(.start) {
		section#start {
			// opacity: 0;
        }
        .particles-bg,
        #particles-js {
            opacity: 1;
        }
    }
    &.legal {
        .product-image,
        .floating-container,
		.floating-object, {
			visibility: hidden;
			opacity: 0;
			margin-top: 100px;
        }
        .particles-bg,
        #particles-js {
            opacity: 0;
        }
    }
    &.square-screen {
        section {
            height: auto !important;
            max-height: none !important;
            padding-top: 30px;
            padding-bottom: 30px;
            @media #{$lg} {
                &:not(#start) {
                    .wrapper {
                        display: flex;
                        justify-content: flex-end;
                        .page-content {
                            // margin-top: 20px;
                            // margin-bottom: 20px;
                            @include relative_auto();
                            transform: translate(0,0);
                        }
                    }
                }
            }
        }
        &:not(.startt) {
            .floating-container {
                @media #{$lg} {
                    visibility: visible !important;
                    opacity: 1 !important;
                    margin-top: 0 !important;
                }
            }
        }
        .trigger-next {
            display: none;
        }
        .product-name-section {
            visibility: visible;
            opacity: 1;
            margin-top: 0;
        }
        .product-image {
            top: auto;
            bottom: 40%;
            transform: translateY(50%);
            height: 70vh;
            max-height: 712px;
            @media #{$hg} {
                left: 0;
            }
        }
        .floating-container {
            top: auto;
            bottom: 50%;
            transform: translateY(50%);
            height: 70vh;
            max-height: 712px;
            @media #{$hg} {
                left: 0;
            }
        }
        #start {
            height: 100vh !important;
            background: url(../img/bg-hero-lg.png) 130% top no-repeat,;
            background-size: auto 100vh;
            .wrapper {
                display: flex;
                align-items: center;
                .product-name {
                    display: none;
                }
                .content {
                    @include size(auto,auto);
                    display: flex;
                    flex-direction: column;
                    margin-left: 36%;
                }
            }
        }
    }
    // &.dev {
    //     .temp {
    //         display: block;
    //     }
    // }
}

.legal-box .btns .col:first-of-type {
	display: flex;
	flex-direction: column;
}
.legal-box .btns .col:first-of-type .smallest {
	margin-bottom: 0;
}
#start > .wrapper {
	padding-bottom:100px;
}
#wieksze-opakowanie > .wrapper {
	padding-bottom:100px;
}
.wrapper {
    margin: 0 auto;
    width: vw-calc(1600);
    position: relative;
    @media #{$hg} {
        width: 1600px;
    }
    @media #{$sm} {
        width: 90%;
    }
    @media #{$md} {
        width: vw-tablet(800);
        max-width: vw-tablet(800);
    }

    .page-content {
        // @include absolute(50%,0,auto,auto,5);
        // @include size(50%,auto);
        // transform: translateY(-50%);
        @media #{$lg} {
            position: relative;
            right: 0;
            transform: translate(0,0);
            top: 0;
            margin-left: 50%;
        }

        @media #{$sm} {
            @include relative_auto();
            width: 100%;
            transform: translateY(0);
        }
        @media #{$md} {
            @include relative_auto();
            width: 100%;
            transform: translateY(0);
        }
    }
}

img {
    max-width: 100%;
}

@font-face {
    font-family: 'AccuraSBI';
    src: url('../fonts/Accura-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Accura-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'AccuraBI';
    src: url('../fonts/Accura-BoldItalic.woff2') format('woff2'),
        url('../fonts/Accura-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'AccuraI';
    src: url('../fonts/Accura-RegularItalic.woff2') format('woff2'),
        url('../fonts/Accura-RegularItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'AccuraL';
    src: url('../fonts/Accura-Light.woff2') format('woff2'),
        url('../fonts/Accura-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Accura';
    src: url('../fonts/Accura-Regular.woff2') format('woff2'),
        url('../fonts/Accura-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AccuraLI';
    src: url('../fonts/Accura-LightItalic.woff2') format('woff2'),
        url('../fonts/Accura-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'AccuraBLI';
    src: url('../fonts/Accura-BlackItalic.woff2') format('woff2'),
        url('../fonts/Accura-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'AccuraSB';
    src: url('../fonts/Accura-SemiBold.woff2') format('woff2'),
        url('../fonts/Accura-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'AccuraB';
    src: url('../fonts/Accura-Bold.woff2') format('woff2'),
        url('../fonts/Accura-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AccuraULI';
    src: url('../fonts/Accura-UltraLightItalic.woff2') format('woff2'),
        url('../fonts/Accura-UltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'AccuraT';
    src: url('../fonts/Accura-Thin.woff2') format('woff2'),
        url('../fonts/Accura-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'AccuraUL';
    src: url('../fonts/Accura-UltraLight.woff2') format('woff2'),
        url('../fonts/Accura-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'AccuraBL';
    src: url('../fonts/Accura-Black.woff2') format('woff2'),
        url('../fonts/Accura-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'AccuraTI';
    src: url('../fonts/Accura-ThinItalic.woff2') format('woff2'),
        url('../fonts/Accura-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}
