@media #{$sm} { .hide-sm {display: none !important; }}
@media #{$md} { .hide-md {display: none !important; }}
@media #{$lg} { .hide-lg {display: none !important; }}
@media #{$lgOnly} { .hide-lg-only {display: none !important; }}
@media #{$hg} { .hide-hg {display: none !important; }}

body.noScroll {
	overflow:  hidden;
}

.off {
    pointer-events: none;    
}

.valign {
  display: inline-flex;
  align-items: center;
}

body.noScroll {
	overflow:  hidden;
}

.off {
    pointer-events: none;    
}

@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

@function vw-calc($size) {
  $vwSize: $size / 1920 * 100;
  @return #{$vwSize}vw;
}
@function vw-tablet($size) {
  $vwSize: $size / 1100 * 100;
  @return #{$vwSize}vw;
}
@function vw-mobile($size) {
  $vwSize: $size / 640 * 100;
  @return #{$vwSize}vw;
}

@function vh-calc($size) {
  $vhSize: $size / 1080 * 100;
  @return #{$vhSize}vh;
}

@keyframes bouncing {
  0% {
    transform: translate(-50%,15px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%,25px) rotate(45deg);
    opacity: 0;
  }
}
@keyframes preloader {
  0% {
    transform: rotate(0deg);    
  }
  50% {
    transform: rotate(180deg);    
  }
  100% {
    transform: rotate(360deg);    
  }
}

@keyframes floating1 {
  0% {
    transform: translate(0,0);    
  }
  50% {
    transform: translate(10px,-5px);    
  }  
  100% {
    transform: translate(0,0);    
  }  
}

@keyframes floating2 {
  0% {
    transform: translate(0,0);    
  }
  25% {
    transform: translate(15px,15px);    
  }
  25% {
    transform: translate(0px,30px);    
  }
  75% {
    transform: translate(-15px,15px);    
  }
  100% {
    transform: translate(0,0);    
  } 
}

@keyframes floating3 {
  0% {
    transform: translate(0,0);    
  }
  25% {
    transform: translate(15px,15px);    
  }
  25% {
    transform: translate(0px,30px);    
  }
  75% {
    transform: translate(-15px,15px);    
  }
  100% {
    transform: translate(0,0);    
  } 
}
