@mixin absolute($t,$r,$b,$l,$z) {
  position: absolute;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
  z-index: $z;
}
@mixin fixed($t,$r,$b,$l,$z) {
  position: fixed;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
  z-index: $z;
}
@mixin relative($t,$r,$b,$l,$z) {
  position: fixed;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
  z-index: $z;
}
@mixin relative_auto() {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: auto;
}
@mixin size($w,$h) {
  width: $w;
  height: $h;   
}
@mixin max($w,$h) {
  max-width: $w;
  max-height: $h;   
}
@mixin transition($variant) {
  @if $variant == 'short' {
    transition: all 150ms ease-in-out;
  } @else if $variant == 'medium' {
    transition: all 250ms ease-in-out;
  } @else if $variant == 'long' {
    transition: all 450ms ease-in-out;  
  } @else if $variant == 'express' {
    transition: all 50ms ease-in-out;
  }
}

@mixin no-select() {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}