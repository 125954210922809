$sm: "only screen and (min-width: 0) and (max-width: 768px)";
// $md: "only screen and (min-width: 641px) and (max-width: 1100px)";
$md: "only screen and (min-width: 769px) and (max-width: 1149px)";
// $lg: "only screen and (min-width: 1101px)";
$lg: "only screen and (min-width: 1150px)";
// $lgOnly: "only screen and (min-width: 1101px) and (max-width: 1920px)";
$lgOnly: "only screen and (min-width: 1150px) and (max-width: 1920px)";
$hg: "only screen and (min-width: 1921px)";

// $colorPrimary: #00205b;
$colorPrimary: #0c57b0;
$colorSecondary: #013879;
$colorTertiary: #de047e;

$headerBg: #fff;

$globalPadding: 40px;
// $headerSize: 70px;
$headerSize: 70px;
$headerSizeMobile: 70px;
$asideWidth: 200px;
$maxWidth: 1500px;

$globalFont: 'Accura';
$globalFontBold: 'AccuraB';
$globalFontBlack: 'AccuraBL';
$globalFontLight: 'AccuraL';

$fontSizeHugeH2: 42px;
$fontSizeHugeL: 20px;
$fontSizeHugeS: 15px;
$fontSizeHugeXS: 11px;
$fontSizeDesktopH2: 4.5vmin;
// $fontSizeDesktopL: 2.25vmin;
$fontSizeDesktopL: 1.95vmin;
$fontSizeDesktopS: 1.675vmin;
$fontSizeDesktopXS: 1.175vmin;
$fontSizeTabletH2: 3.1vw;
$fontSizeTabletH3: 2.2vw;
$fontSizeTabletXXL: 3.7vw;
$fontSizeTabletXL: 3.0vw;
$fontSizeTabletL: 2.4vw;
$fontSizeTabletM: 2.0vw;
$fontSizeTabletS: 1.8vw;
$fontSizeTabletXS: 1.8vw;
$fontSizeMobileH2: vw-mobile(42);
$fontSizeMobileXXL: 4.2vw;
$fontSizeMobileXL: 4.0vw;
$fontSizeMobileL: 4.0vw;
$fontSizeMobileS: 3.5vw;
$fontSizeMobileXS: 3.5vw;

$horizontalBorderSize: 20px;
$mainMarginTop: 60px;


%transitions {
	transition: all 350ms ease-in-out;
}
