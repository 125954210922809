#onetrust-consent-sdk #onetrust-banner-sdk{
    background-color: #F5F5F5 !important;
}
#onetrust-consent-sdk #onetrust-policy-title, #onetrust-consent-sdk #onetrust-policy-text, #onetrust-consent-sdk .ot-b-addl-desc, #onetrust-consent-sdk .ot-dpd-desc, #onetrust-consent-sdk .ot-dpd-title, #onetrust-consent-sdk #onetrust-policy-text *:not(.onetrust-vendors-list-handler), #onetrust-consent-sdk .ot-dpd-desc *:not(.onetrust-vendors-list-handler), #onetrust-consent-sdk #onetrust-banner-sdk #banner-options * {
    color: #1F140C !important;   
}
#onetrust-consent-sdk #onetrust-banner-sdk a[href]{
    color: #051E79 !important;
}
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link{
    border-color: #F5F5F5 !important;
    background-color: #F5F5F5 !important;
}